import { AppStore } from "store/App.store";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import moment from "moment";
import { AnalyticsDateRangeFilterEnum } from "enums/analytics-date-range-filter.enum";

const DEFAULT_DATE_RANGE = {
  type: AnalyticsDateRangeFilterEnum.CUSTOM,
  startDate: moment()
    .subtract(31, "day")
    .startOf("day")
    .toISOString(),
  endDate: moment()
    .subtract(1, "day")
    .endOf("day")
    .toISOString(),
};

type TAnalyticsDateRangeFilterConfiguration = {
  type?: AnalyticsDateRangeFilterEnum;
  startDate: string | null;
  endDate: string | null;
};

export class AccountAnalyticsFiltersStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private dateConfig: TAnalyticsDateRangeFilterConfiguration;
  private officeIds: IObservableArray<number>;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.root = root;
    this.accountId = accountId;
    this.dateConfig = DEFAULT_DATE_RANGE;
    this.officeIds = observable.array<number>();
  }

  get dateRange(): TAnalyticsDateRangeFilterConfiguration {
    return this.dateConfig;
  }

  get officeIdsArray() {
    return this.officeIds.slice();
  }

  public changeDateRange(config: TAnalyticsDateRangeFilterConfiguration) {
    this.dateConfig = {
      type: config.type || AnalyticsDateRangeFilterEnum.CUSTOM,
      startDate: config.startDate,
      endDate: config.endDate,
    };
  }

  public setOfficeIds(officeIds: number[]) {
    this.officeIds.replace(officeIds);
  }

  public resetFilters() {
    this.setOfficeIds([]);
    this.changeDateRange(DEFAULT_DATE_RANGE);
  }
}
