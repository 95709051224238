export enum FormControlsTypeEnum {
  CERTIFICATE_UPLOAD_INPUT = "certificate-upload-input",
  SELECT_AND_INPUT = "select-and-input",
  TIME_RANGE_PICKER = "time-range-picker",
  TIME_PICKER = "time-picker",
  DATE_PICKER = "date-picker",
  TAG_INPUT = "tag-input",
  SINGLE_SELECT = "single-select",
  MULTI_SELECT = "multi-select",
  RADIO = "radio",
  TEXT = "text",
  TEXTAREA = "textarea",
  TEL = "tel",
  PASSWORD = "password",
  SWITCH = "switch",
  COLOR_INPUT = "color-input",
  PICTURE_UPLOAD_INPUT = "picture-upload-input",
  HOST_INPUT = "host-input",
  PERSISTENT_FILTER_CONFIGURATOR = "persistent-filter-configurator",
  MERGE_TAG_INPUT = "merge-tag-input",
  CHECKBOX = "checkbox",
}
