import { AccountAltoApi } from "api/account-alto.api";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import {
  TAltoNegotiator,
  TAltoNegotiatorRelationData,
  TAltoNegotiatorRelationExtendedData,
} from "types/alto.type";
import { keyBy } from "lodash";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { AppStore } from "store/App.store";
import { getUserFullName } from "utils/account-user.utils";

const accountAltoApi = new AccountAltoApi();

export class AccountIntegrationAltoNegotiatorsStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private accountDefaultNegotiator: string | null;
  private readonly accountAltoNegotiators: IObservableArray<TAltoNegotiator>;
  private readonly accountAltoNegotiatorsRelations: IObservableArray<
    TAltoNegotiatorRelationData
  >;
  private syncStatusFilter: boolean;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.root = root;
    this.accountId = accountId;
    this.accountDefaultNegotiator = null;
    this.accountAltoNegotiators = observable.array<TAltoNegotiator>();
    this.accountAltoNegotiatorsRelations = observable.array<
      TAltoNegotiatorRelationData
    >();
    this.syncStatusFilter = false;
  }

  get altoNegotiatorsRelationsMapByUserAccountId() {
    return keyBy(
      this.accountAltoNegotiatorsRelations,
      relation => relation.userAccountId
    );
  }

  get altoNegotiatorsRelationsMapByAltoId() {
    return keyBy(
      this.accountAltoNegotiatorsRelations,
      relation => relation.altoId
    );
  }

  public setSyncStatusFilter(value: boolean) {
    this.syncStatusFilter = value;
  }

  get getSyncStatusFilter() {
    return this.syncStatusFilter;
  }

  get filteredAltoSyncNegotiatorData() {
    return this.altoSyncNegotiatorsData.filter(
      syncData =>
        !this.syncStatusFilter ||
        syncData.syncStatus !== IntegrationEntitySyncStatusEnum.SYNCED
    );
  }

  get altoSyncNegotiatorsData(): TAltoNegotiatorRelationExtendedData[] {
    const accountStore = this.root.userAccountsStore.userAccountsMap[
      this.accountId
    ];

    const accountsUsersStore = accountStore.accountUsersStore;
    const leadproAccountUsers = accountsUsersStore.accountUsersArray;

    const altoNegotiatorsRelationsMapByUserAccountId = this
      .altoNegotiatorsRelationsMapByUserAccountId;

    return leadproAccountUsers.map(accountUser => {
      let pairedAltoNegotiatorId =
        altoNegotiatorsRelationsMapByUserAccountId[accountUser.userAccount.id]
          ?.altoId;
      let syncStatus = !!pairedAltoNegotiatorId
        ? IntegrationEntitySyncStatusEnum.SYNCED
        : IntegrationEntitySyncStatusEnum.NOT_SYNCED;

      return {
        altoId: pairedAltoNegotiatorId,
        userAccountId: accountUser.userAccount.id,
        userName: getUserFullName(
          accountUser.firstName,
          accountUser.lastName,
          accountUser.email
        )!,
        syncStatus: syncStatus,
      };
    });
  }

  get altoNegotiatorsOptions() {
    return this.accountAltoNegotiators
      .map(altoNegotiator => ({
        value: altoNegotiator.altoId,
        label: altoNegotiator.altoName,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  get altoNegotiatorsOptionsWithData() {
    return this.accountAltoNegotiators
      .map(altoNegotiator => ({
        value: altoNegotiator.altoId,
        label: altoNegotiator.altoName,
        data: this.altoNegotiatorsRelationsMapByAltoId[altoNegotiator.altoId],
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  get defaultNegotiatorId() {
    return this.accountDefaultNegotiator;
  }

  public setAccountAltoNegotiators(negotiators: TAltoNegotiator[]) {
    this.accountAltoNegotiators.replace(negotiators);
  }

  public setAccountAltoNegotiatorsRelations(
    relations: TAltoNegotiatorRelationData[]
  ) {
    this.accountAltoNegotiatorsRelations.replace(relations);
  }

  public setAccountDefaultAltoNegotiatorId(negotiatorId: string | null) {
    this.accountDefaultNegotiator = negotiatorId;
  }

  public async fetchAccountAltoNegotiators() {
    const data = await accountAltoApi.fetchAccountAltoNegotiators(
      this.accountId
    );
    this.setAccountAltoNegotiators(data);
  }

  public async fetchAccountAltoNegotiatorsRelations() {
    const data = await accountAltoApi.fetchAccountAltoNegotiatorRelations(
      this.accountId
    );
    this.setAccountAltoNegotiatorsRelations(data);
  }

  public async fetchAccountDefaultAltoNegotiatorId() {
    const data = await accountAltoApi.fetchAccountDefaultAltoNegotiatorId(
      this.accountId
    );

    this.setAccountDefaultAltoNegotiatorId(data.altoId);
  }

  public async fetchAccountAltoNegotiatorsAndRelations() {
    await Promise.all([
      this.fetchAccountAltoNegotiators(),
      this.fetchAccountAltoNegotiatorsRelations(),
      this.fetchAccountDefaultAltoNegotiatorId(),
    ]);
  }

  public async updateAccountAltoSyncedNegotiators(
    syncedPairs: TAltoNegotiatorRelationData[]
  ) {
    await accountAltoApi.updateAccountAltoSyncedNegotiators(
      this.accountId,
      syncedPairs.map(pair => ({
        userAccountId: pair.userAccountId,
        altoId: pair.altoId,
      }))
    );
    await this.fetchAccountAltoNegotiatorsAndRelations();
  }

  public async updateAccountDefaultAltoNegotiatorId() {
    await accountAltoApi.updateAccountDefaultAltoNegotiatorId(
      this.accountId,
      this.accountDefaultNegotiator
    );
  }
}
