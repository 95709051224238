import { observer } from "mobx-react";
import {
  Box,
  Flex,
  Grid,
  Radio,
  RadioGroup,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { AccountIntegrationsStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrations.store";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import {
  TAccountIntegrationData,
  TAltoConfig,
} from "types/account-integration.type";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { ApiMessageStack } from "components";
import { useCallback } from "react";

interface IProps {
  accountIntegrationsStore: AccountIntegrationsStore;
}

export const AltoIntegrationSyncConfigurator: React.FC<IProps> = observer(
  ({ accountIntegrationsStore }) => {
    const toast = useToast();

    const accountIntegration = accountIntegrationsStore
      .accountIntegrationsMapByType[
      AccountIntegrationTypeEnum.ALTO
    ] as TAccountIntegrationData<TAltoConfig>;

    const handleManuallyTriggeredChange = useCallback(
      async (value: string) => {
        const isManuallyTriggered = value === "true";
        const {
          agencyRef,
          altoNegotiatorId,
        } = accountIntegration.integrationConfig;
        try {
          await accountIntegrationsStore.updateAccountIntegration(
            accountIntegration.id,
            {
              type: accountIntegration.type,
              active: true,
              isManuallyTriggered: isManuallyTriggered,
              integrationConfig: {
                agencyRef,
                altoNegotiatorId,
              },
            }
          );
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack
                messageStack={
                  "Alto integration configuration successfully updated."
                }
              />
            ),
          });
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        }
      },
      [accountIntegration, accountIntegrationsStore, toast]
    );
    return (
      <RadioGroup
        onChange={value => handleManuallyTriggeredChange(value)}
        value={accountIntegration.isManuallyTriggered ? "true" : "false"}
      >
        <Grid templateColumns="75% 25%" gap={4}>
          <VStack alignItems={"left"} justifyContent={"center"} height="100px">
            <Box fontSize={"lg"}>Automatic</Box>
            <Box color={"leadpro.400"}>
              All leads that are created in your Leadpro dashboard will be
              attempted to be automatically sync'd into Alto.
            </Box>
          </VStack>
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            height={"100px"}
          >
            <Radio size={"lg"} value="false" />
          </Flex>
          <VStack alignItems={"left"} justifyContent={"center"} height="100px">
            <Box fontSize={"lg"}>Manual</Box>
            <Box color={"leadpro.400"}>
              No leads will be automatically sync'd into Alto upon creation.
              Instead, your agents will be required to press the 'Sync to Alto'
              button available at the top right of a lead's details panel.
            </Box>
          </VStack>
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            height={"100px"}
          >
            <Radio size={"lg"} value="true" />
          </Flex>
        </Grid>
      </RadioGroup>
    );
  }
);
