import { MessageParams } from "yup/lib/types";

export const REQUIRED_FIELD = "This field is required.";
export const MEMBERSHIP_REQUIRED_FIELD = "Please select membership.";
export const INVALID_PHONE_NUMBER = "Phone number is not valid.";
export const INVALID_MOBILE_PHONE_NUMBER =
  "The number you entered is not a valid mobile phone number.";
export const INVALID_PHONE_AUTO_ATTENDANT = "Phone keypress is not valid.";
export const PASSWORDS_MISMATCH = "Both password need to be the same.";
export const GREATER_TIME_FIELD = "Second time should be greater.";
export const FIRST_TIME_MISSING = "First time is missing.";
export const BAD_NAME = "Name you entered is not correct.";
export const SELECT_AT_LEAST_ONE = "Please select at least one.";
export const INVALID_HEX_COLOR = "Invalid hex code.";
export const INVALID_DOMAIN_NAME = "Invalid domain name.";
export const URL_FIELD = "This field must be valid URL.";
export const ALPHANUMERIC_CHARS_ONLY =
  "Only alphanumeric characters with spaces and full stops are allowed.";
export const URL_FIELD_MISSING_PROTOCOL =
  "This field must contain http:// or https://";
export const FILE_TOO_LARGE = "The file is too large";
export const GOOGLE_ADS_CONVERSION_LABEL_ID_REQUIRED =
  "Conversion Label ID is required when setting up Google Ads Conversion tracking";
export const TWO_FACTOR_AUTH_CODE_REQUIRED_TO_ENABLE =
  "Verification code is required to enable 2FA.";
export const TWO_FACTOR_AUTH_CODE_REQUIRED_TO_ACCESS =
  "Verification code is required to access your account.";
export const TWO_FACTOR_AUTH_CODE_LENGTH =
  "Verification code must be 6 digits long.";
export const OFFICE_POSTCODE_LIMIT =
  "Each office must have 150 postcodes or less.";
export const TOKEN_MIN_LENGTH_10 = "token must be at least 10 characters long.";

// Dynamic messages
export const getInvalidUKPostcodeMessage = (messageProps: MessageParams) => {
  return `value ${messageProps.value} does not match UK postcode format.`;
};
