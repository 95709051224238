import { AppStore } from "../../App.store";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import {
  TLeadSourceTypeData,
  TNormalizedLeadSourceData,
} from "types/lead-source.type";
import { keyBy } from "lodash";
import { normalize, schema } from "normalizr";
import { AccountApi } from "api/account.api";

const accountAPI = new AccountApi();
const leadSourceTypesEntity = new schema.Entity("leadSourceTypes");
const leadSourcesEntity = new schema.Entity("leadSources", {
  leadSourceTypes: [leadSourceTypesEntity],
});

export class AccountLeadSourcesStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private readonly leadSources: IObservableArray<TNormalizedLeadSourceData>;
  private readonly leadSourceTypes: IObservableArray<TLeadSourceTypeData>;
  private readonly currentlyActiveLeadSources: IObservableArray<
    TNormalizedLeadSourceData
  >;
  private readonly currentlyActiveLeadSourceTypes: IObservableArray<
    TLeadSourceTypeData
  >;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
    this.accountId = accountId;
    this.leadSources = observable.array<TNormalizedLeadSourceData>();
    this.leadSourceTypes = observable.array<TLeadSourceTypeData>();
    this.currentlyActiveLeadSources = observable.array<
      TNormalizedLeadSourceData
    >();
    this.currentlyActiveLeadSourceTypes = observable.array<
      TLeadSourceTypeData
    >();
  }

  get leadSourcesArray() {
    return this.leadSources.slice();
  }

  get leadSourcesMap() {
    return keyBy(this.leadSources, leadSource => leadSource.id);
  }

  get leadSourcesMapBySource() {
    return keyBy(this.leadSources, leadSource => leadSource.source);
  }

  get leadSourceTypesMap() {
    return keyBy(this.leadSourceTypes, leadSourceType => leadSourceType.id);
  }

  get currentlyActiveLeadSourcesArray() {
    return this.currentlyActiveLeadSources.slice();
  }

  private setLeadSources(leadSources: TNormalizedLeadSourceData[]) {
    this.leadSources.replace(leadSources);
  }

  private setLeadSourceTypes(leadSourceTypes: TLeadSourceTypeData[]) {
    this.leadSourceTypes.replace(leadSourceTypes);
  }

  private setCurrentlyActiveLeadSources(
    leadSources: TNormalizedLeadSourceData[]
  ) {
    this.currentlyActiveLeadSources.replace(leadSources);
  }

  private setCurrentlyActiveLeadSourceTypes(
    leadSourceTypes: TLeadSourceTypeData[]
  ) {
    this.currentlyActiveLeadSourceTypes.replace(leadSourceTypes);
  }

  public async fetchAccountLeadSources() {
    const leadSources = await accountAPI.fetchAccountLeadSources(
      this.accountId
    );
    const normalizedData = normalize<
      any,
      {
        leadSources: Record<number, TNormalizedLeadSourceData>;
        leadSourceTypes: Record<number, TLeadSourceTypeData>;
      },
      any
    >(leadSources, [leadSourcesEntity]);

    this.setLeadSources(
      Array.from(Object.values(normalizedData.entities.leadSources))
    );
    this.setLeadSourceTypes(
      Array.from(Object.values(normalizedData.entities.leadSourceTypes))
    );
  }

  public async fetchCurrentlyActiveAccountLeadSources() {
    const leadSources = await accountAPI.fetchCurrentlyActiveAccountLeadSources(
      this.accountId
    );
    const normalizedData = normalize<
      any,
      {
        leadSources: Record<number, TNormalizedLeadSourceData>;
        // leadSourceTypes: Record<number, TLeadSourceTypeData>;
      },
      any
    >(leadSources, [leadSourcesEntity]);

    this.setCurrentlyActiveLeadSources(
      Array.from(Object.values(normalizedData.entities.leadSources))
    );
    // this.setCurrentlyActiveLeadSourceTypes(
    //   Array.from(Object.values(normalizedData.entities.leadSourceTypes))
    // );
  }
}
