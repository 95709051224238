import React, { FC, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Error, Loader } from "components";
import { AltoIntegrationsLeadSourcesConfigurator } from "./AltoIntegrationsSourcesConfigurator/AltoIntegrationsLeadSourcesConfigurator";
import { OfficeSelectIntegrationTable } from "../AccountIntegrationConfigurator/OfficeSelectIntegrationTable";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import { AltoIntegrationSyncConfigurator } from "./AltoIntegrationSyncConfigurator";
import { AltoIntegrationsIntentionsConfigurator } from "./AltoIntegrationIntentionsConfigurator/AltoIntegrationsIntentionsConfigurator";
import { AltoIntegrationsNegotiatorsConfigurator } from "./AltoIntegrationsNegotiatorsConfigurator/AltoIntegrationsNegotiatorsConfigurator";
import { AltoIntegrationDefaultNegotiatorConfigurator } from "./AltoIntegrationDefaultNegotiatorConfiguration/AltoIntegrationDefaultNegotiatorConfigurator";

interface IProps {
  accountStore: UserAccountStore;
}

export const AltoIntegrationConfigurator: FC<IProps> = observer(
  ({ accountStore }) => {
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );

    const accountLeadSourcesStore = accountStore.accountLeadSourcesStore;
    const accountOfficesStore = accountStore.accountOfficesStore;
    const accountUsersStore = accountStore.accountUsersStore;
    const accountIntegrationsStore = accountStore.accountIntegrationsStore;
    const isManuallyTriggered =
      accountIntegrationsStore.hasActiveManualAltoIntegration;
    const accountIntegrationsAltoStore =
      accountIntegrationsStore.accountIntegrationAltoStore;
    const accountIntegrationOfficesStore =
      accountIntegrationsAltoStore.accountIntegrationAltoOfficesStore;
    const accountIntegrationLeadSourcesStore =
      accountIntegrationsAltoStore.accountIntegrationAltoLeadSourcesStore;
    const accountIntegrationIntentionsStore =
      accountIntegrationsAltoStore.accountIntegrationAltoIntentionsStore;
    const accountIntegrationAltoNegotiatorsStore =
      accountIntegrationsAltoStore.accountIntegrationAltoNegotiatorsStore;

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await Promise.all([
            accountOfficesStore.fetchAccountOffices(),
            accountLeadSourcesStore.fetchCurrentlyActiveAccountLeadSources(),
            accountIntegrationOfficesStore.fetchAccountIntegrationOfficesAndRelations(),
            accountIntegrationLeadSourcesStore.fetchAccountAltoLeadSourcesAndRelations(),
            accountIntegrationIntentionsStore.fetchAccountAltoIntentionsAndRelations(),
            accountIntegrationAltoNegotiatorsStore.fetchAccountAltoNegotiatorsAndRelations(),
            accountUsersStore.fetchAccountUsers(),
          ]);
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [
      accountIntegrationsAltoStore,
      accountOfficesStore,
      accountUsersStore,
      accountLeadSourcesStore,
      accountIntegrationOfficesStore,
      accountIntegrationLeadSourcesStore,
      accountIntegrationIntentionsStore,
      accountIntegrationAltoNegotiatorsStore,
    ]);

    return (
      <>
        {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
        {loadingStatus === ApiRequestStatusEnum.ERROR && <Error />}
        {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
          <>
            <AltoIntegrationSyncConfigurator
              accountIntegrationsStore={accountIntegrationsStore}
            />
            <OfficeSelectIntegrationTable
              accountIntegrationOfficesStore={accountIntegrationOfficesStore}
              accountIntegrationsStore={accountIntegrationsStore}
              integrationType={AccountIntegrationTypeEnum.ALTO}
            />
            <AltoIntegrationDefaultNegotiatorConfigurator
              accountStore={accountStore}
              isManuallyTriggered={isManuallyTriggered}
            />
            <AltoIntegrationsNegotiatorsConfigurator
              accountStore={accountStore}
              isManuallyTriggered={isManuallyTriggered}
            />
            <AltoIntegrationsLeadSourcesConfigurator
              accountStore={accountStore}
            />
            <AltoIntegrationsIntentionsConfigurator
              accountStore={accountStore}
            />
          </>
        )}
      </>
    );
  }
);
