export enum AccountIntegrationTypeEnum {
  DRIP = "drip",
  ACQUAINT = "acquaint",
  DEZREZ = "dezrez",
  REAPIT = "reapit",
  REAPIT_INTERNET_REGISTRATION = "reapit_internet_registration",
  JUPIX = "jupix",
  ALTO = "alto",
  ZAPIER = "zapier",
  AGENT_OS = "agent_os",
  MRI_QUBE = "mri_qube",
  MRI_RADAR = "mri_radar",
  ZOOPLA = "zoopla",
  RIGHTMOVE = "rightmove",
  STREET = "street",
  GRAINGER_SALESFORCE = "grainger_salesforce",
  OPEN_VIEW = "openview",
  REX = "rex",
  URL_SYNC = "url_sync",
  BYM_DATAWAREHOUSE = "bym_datawarehouse",
}

export const ManuallyTriggerableIntegrationTypes = [
  AccountIntegrationTypeEnum.REAPIT,
  AccountIntegrationTypeEnum.ALTO,
  AccountIntegrationTypeEnum.MRI_RADAR,
  AccountIntegrationTypeEnum.URL_SYNC,
  AccountIntegrationTypeEnum.OPEN_VIEW,
];
