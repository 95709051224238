import { Box, VStack } from "@chakra-ui/react";
import { FC, ReactNode, useCallback, useMemo } from "react";
import { AccountEmailNotificationPreferenceEnum } from "enums/account-email-notification-preference.enum";

interface IProps {
  value: AccountEmailNotificationPreferenceEnum;
  selectedValue: AccountEmailNotificationPreferenceEnum;
  icon: ReactNode;
  title: ReactNode;
  description: ReactNode;
  onClick: (value: AccountEmailNotificationPreferenceEnum) => void;
}
export const AccountEmailNotificationsItem: FC<IProps> = ({
  value,
  selectedValue,
  icon,
  title,
  description,
  onClick,
}) => {
  const isSelected = useMemo(() => value === selectedValue, [
    value,
    selectedValue,
  ]);

  const style = useMemo(() => {
    return {
      base: {
        spacing: 2,
        padding: 5,
        rounded: "md",
        align: "flex-start",
        cursor: "pointer",
        bg: isSelected ? "white" : "gray.50",
        border: "1px solid",
        borderColor: "gray.100",
        outline: isSelected ? "solid 2px" : "none",
        outlineColor: isSelected ? "green.100" : "none",
      },
      icon: {
        bg: isSelected ? "green.100" : "white",
        rounded: "full",
        padding: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: 48.5,
        minHeight: 48.5,
      },
    };
  }, [isSelected]);

  const handleClick = useCallback(() => onClick(value), [onClick, value]);

  return (
    <VStack {...style.base} onClick={handleClick}>
      <Box {...style.icon}>{icon}</Box>
      <Box>{title}</Box>
      <Box fontSize={"sm"} fontWeight={"normal"} color={"gray.500"}>
        {description}
      </Box>
    </VStack>
  );
};
