import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ApiMessageStack, FormControlV2 } from "components";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { observer } from "mobx-react";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { AccountIntegrationsStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrations.store";
import {
  openViewIntegrationConnectValidationSchema,
  openViewIntegrationInitialValues,
  TOpenViewIntegrationConfigFieldValues,
} from "utils/validation-schemas/openview-integration-validation";
import { SETTINGS_INTEGRATIONS_ROUTE } from "../../../../../constants/routes";
import { useHistory } from "react-router-dom";

interface IProps {
  accountIntegrationsStore: AccountIntegrationsStore;
  closePrompt: () => void;
}

export const ConnectOpenViewForm: FC<IProps> = observer(
  ({ accountIntegrationsStore, closePrompt }) => {
    const toast = useToast();
    const history = useHistory();

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TOpenViewIntegrationConfigFieldValues>({
      defaultValues: openViewIntegrationInitialValues,
      mode: "onSubmit",
      resolver: yupResolver(openViewIntegrationConnectValidationSchema),
    });

    const onSubmit = useCallback(
      async (integrationConfig: TOpenViewIntegrationConfigFieldValues) => {
        try {
          await accountIntegrationsStore.createAccountIntegration<
            TOpenViewIntegrationConfigFieldValues
          >({
            type: AccountIntegrationTypeEnum.OPEN_VIEW,
            active: true,
            integrationConfig,
          });

          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack
                messageStack={"OpenView successfully connected."}
              />
            ),
          });
          closePrompt();
          history.push(
            `${SETTINGS_INTEGRATIONS_ROUTE}/${AccountIntegrationTypeEnum.OPEN_VIEW}`
          );
        } catch (e) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={e?.message} />,
          });
        }
      },
      [accountIntegrationsStore, closePrompt, toast, history]
    );

    return (
      <Box pt={5}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={5}>
            <FormControlV2<TOpenViewIntegrationConfigFieldValues>
              label={"Client Id"}
              type={FormControlsTypeEnum.TEXT}
              name={"openViewClientId"}
              control={control}
            />
          </Box>
          <Box mb={5}>
            <FormControlV2<TOpenViewIntegrationConfigFieldValues>
              label={"Client Secret"}
              type={FormControlsTypeEnum.TEXT}
              name={"openViewClientSecret"}
              control={control}
            />
          </Box>
          <Box mb={5}>
            <FormControlV2<TOpenViewIntegrationConfigFieldValues>
              label={"Auto-assign leads"}
              type={FormControlsTypeEnum.SWITCH}
              name={"isLeadAutoAssignable"}
              control={control}
            />
          </Box>
          <Divider mb={4} />
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-end"}
          >
            <Button
              type={"submit"}
              colorScheme={"blue"}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Connect
            </Button>
          </Box>
        </form>
      </Box>
    );
  }
);
