import { ApiMessageStack, Error, Loader } from "components";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import React, { FC, useEffect, useMemo, useState } from "react";
import {
  PanelsGroupLayout,
  RightSideLayout,
} from "routes/dashboard/components";
import { observer } from "mobx-react";
import { AnalyticsContent } from "./components/AnalyticsContent";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { AnalyticsFiltersRow } from "./components/AnalyticsFiltersRow/AnalyticsFiltersRow";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";
import { Box, useToast } from "@chakra-ui/react";

interface IProps {
  accountStore: UserAccountStore;
}

export const Analytics: FC<IProps> = observer(({ accountStore }) => {
  const {
    accountAnalyticsStore,
    accountOfficesStore: { fetchAccountOffices },
    accountOfficeGroupsStore: { fetchAllAccountOfficeGroups },
  } = accountStore;
  const {
    statsDataRaw,
    loadAnalyticsData,
    loadTotalLeadContacts,
    resetAnalytics,
    accountAnalyticsFiltersStore: { officeIdsArray, dateRange, resetFilters },
  } = accountAnalyticsStore;

  const toast = useToast();
  const [loadingStatusForStats, setLoadingStatusForStats] = useState(
    ApiRequestStatusEnum.NONE
  );
  const [
    loadingStatusForLeadContacts,
    setLoadingStatusForLeadContacts,
  ] = useState(ApiRequestStatusEnum.NONE);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          fetchAccountOffices(),
          fetchAllAccountOfficeGroups(),
        ]);
      } catch (error) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={error.message} />,
        });
      }
    };
    fetchData();

    return () => {
      resetAnalytics();
      resetFilters();
    };
  }, [
    toast,
    fetchAccountOffices,
    fetchAllAccountOfficeGroups,
    resetAnalytics,
    resetFilters,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingStatusForStats(ApiRequestStatusEnum.PENDING);
        await loadAnalyticsData(dateRange);
        setLoadingStatusForStats(ApiRequestStatusEnum.SUCCESS);
      } catch (e) {
        setLoadingStatusForStats(ApiRequestStatusEnum.ERROR);
      }
    };

    fetchData();
  }, [dateRange, loadAnalyticsData, loadTotalLeadContacts]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingStatusForLeadContacts(ApiRequestStatusEnum.PENDING);
        await loadTotalLeadContacts(dateRange, officeIdsArray);
        setLoadingStatusForLeadContacts(ApiRequestStatusEnum.SUCCESS);
      } catch (e) {
        setLoadingStatusForLeadContacts(ApiRequestStatusEnum.ERROR);
      }
    };

    fetchData();
  }, [dateRange, officeIdsArray, loadAnalyticsData, loadTotalLeadContacts]);

  const loadingStatus = useMemo(() => {
    if (
      loadingStatusForStats === ApiRequestStatusEnum.PENDING ||
      loadingStatusForLeadContacts === ApiRequestStatusEnum.PENDING
    ) {
      return ApiRequestStatusEnum.PENDING;
    }

    if (
      loadingStatusForStats === ApiRequestStatusEnum.ERROR ||
      loadingStatusForLeadContacts === ApiRequestStatusEnum.ERROR
    ) {
      return ApiRequestStatusEnum.ERROR;
    }

    if (
      loadingStatusForStats === ApiRequestStatusEnum.SUCCESS &&
      loadingStatusForLeadContacts === ApiRequestStatusEnum.SUCCESS
    ) {
      return ApiRequestStatusEnum.SUCCESS;
    }

    return ApiRequestStatusEnum.NONE;
  }, [loadingStatusForStats, loadingStatusForLeadContacts]);

  const content = useMemo(() => {
    if (loadingStatus === ApiRequestStatusEnum.ERROR) {
      return <Error />;
    }

    return (
      <>
        {loadingStatus === ApiRequestStatusEnum.PENDING && (
          <Box
            display={"flex"}
            position={"absolute"}
            top={0}
            bottom={0}
            left={0}
            right={0}
            zIndex={10}
          >
            <Loader />
          </Box>
        )}
        {!!statsDataRaw && (
          <AnalyticsContent analyticsStore={accountAnalyticsStore} />
        )}
      </>
    );
  }, [loadingStatus, statsDataRaw, accountAnalyticsStore]);

  return (
    <RightSideLayout>
      <PanelsGroupLayout maxW={"6xl"}>
        <AnalyticsFiltersRow accountStore={accountStore} />
        {content}
      </PanelsGroupLayout>
    </RightSideLayout>
  );
});
