import { UseToastOptions } from "@chakra-ui/react";
import { AlertStatusEnum } from "../enums/alert-status.enum";

export const DEFAULT_ERROR_TOAST_OPTIONS: UseToastOptions = {
  title: "Something went wrong.",
  status: AlertStatusEnum.ERROR,
  duration: 5000,
  isClosable: true,
  variant: "subtle",
};

export const DEFAULT_SUCCESS_TOAST_OPTIONS: UseToastOptions = {
  title: "Success!",
  status: AlertStatusEnum.SUCCESS,
  duration: 5000,
  isClosable: true,
  variant: "subtle",
};
