import { AccountEmailNotificationPreferenceEnum } from "enums/account-email-notification-preference.enum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEnvelope,
  faTag,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import { Box } from "@chakra-ui/react";
import React from "react";

export const accountEmailNotificationTilesConfig = [
  {
    value: AccountEmailNotificationPreferenceEnum.EMAILS_OFF,
    icon: <FontAwesomeIcon icon={faXmark} fontSize={26} />,
    title: "Emails Off",
    description: (
      <Box>
        <Box>Leads are only visible on the LeadPro dashboard.</Box>
        <Box>You won't receive any email notifications.</Box>
      </Box>
    ),
  },
  {
    value: AccountEmailNotificationPreferenceEnum.EMAILS_ON,
    icon: <FontAwesomeIcon icon={faCheck} fontSize={26} />,
    title: "Emails On",
    description: (
      <Box>
        Email notification is sent as soon as the lead completes the
        pre-qualification questionnaire, or 15 minutes after contact is
        initiated.
      </Box>
    ),
  },
  {
    value: AccountEmailNotificationPreferenceEnum.PRE_QUALIFIED_LEADS_ONLY,
    icon: <FontAwesomeIcon icon={faTag} fontSize={26} />,
    title: "Pre-qualified leads only",
    description: (
      <Box>
        Only leads that complete the pre-qualification questionnaire are
        emailed.
      </Box>
    ),
  },
  {
    value: AccountEmailNotificationPreferenceEnum.UNQUALIFIED_LEADS_ONLY,
    icon: <FontAwesomeIcon icon={faEnvelope} fontSize={26} />,
    title: "Un-qualified leads only",
    description: (
      <Box>Email notification is sent as soon as the lead is created.</Box>
    ),
  },
];
