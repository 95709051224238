import { Box, Button, useToast } from "@chakra-ui/react";
import { ApiMessageStack, TableV2 } from "components";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { TOfficeGroupData } from "types/account-office.type";
import { observer } from "mobx-react";
import { FC, useMemo, useEffect, useCallback, useState } from "react";
import { ConfirmActionPrompt } from "routes/dashboard/components/prompts/ConfirmActionPrompt";
import { OfficeGroupFormPrompt } from "routes/dashboard/components/prompts/OfficePrompt/OfficeGroupFormPrompt";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { officeGroupsTableColumnDef } from "./officeGroupsTableColumnDef";

interface IProps {
  accountStore: UserAccountStore;
}

export const OfficeGroups: FC<IProps> = observer(({ accountStore }) => {
  const [loadingStatus, setLoadingStatus] = useState<ApiRequestStatusEnum>(
    ApiRequestStatusEnum.NONE
  );
  const { setModal, unSetModal } = useActionPrompt();
  const toast = useToast();
  const officeGroupsStore = accountStore.accountOfficeGroupsStore;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingStatus(ApiRequestStatusEnum.PENDING);
        await officeGroupsStore.fetchAllAccountOfficeGroups();
        setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
      } catch (error) {
        setLoadingStatus(ApiRequestStatusEnum.ERROR);
      }
    };

    fetchData();
  }, [officeGroupsStore, toast]);

  const onDeleteOfficeGroup = useCallback(
    (officeGroupId: number) => async () => {
      try {
        await officeGroupsStore.removeOfficeGroup(officeGroupId);
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack
              messageStack={"Office group successfully removed"}
            />
          ),
        });
      } catch (err) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={err.message} />,
        });
      }
    },
    [officeGroupsStore, toast]
  );

  const handleDeleteOfficeGroupPrompt = useCallback(
    (officeGroup: TOfficeGroupData) => () => {
      setModal(
        <ConfirmActionPrompt
          text={`Please confirm that you want to remove ${officeGroup.name} office group.`}
          onConfirm={onDeleteOfficeGroup(officeGroup.id)}
          closePrompt={unSetModal}
        />
      );
    },
    [onDeleteOfficeGroup, setModal, unSetModal]
  );

  const handleEditOrCreateOfficeGroupFormPrompt = useCallback(
    (officeGroup?: TOfficeGroupData) => async () => {
      setModal(
        <OfficeGroupFormPrompt
          officeGroup={officeGroup}
          closePrompt={unSetModal}
          accountStore={accountStore}
        />
      );
    },
    [setModal, accountStore, unSetModal]
  );

  const handleOnRowClick = useCallback(
    (emailTemplate: TOfficeGroupData) => {
      handleEditOrCreateOfficeGroupFormPrompt(emailTemplate)();
    },
    [handleEditOrCreateOfficeGroupFormPrompt]
  );

  const columnDef = useMemo(() => {
    return officeGroupsTableColumnDef(
      handleDeleteOfficeGroupPrompt,
      handleEditOrCreateOfficeGroupFormPrompt
    );
  }, [handleDeleteOfficeGroupPrompt, handleEditOrCreateOfficeGroupFormPrompt]);

  return (
    <Box>
      <TableV2<TOfficeGroupData>
        dataSource={officeGroupsStore.accountOfficeGroupsArray}
        globalFilterInputPlaceholder="Search office groups"
        columns={columnDef}
        pageSize={20}
        loadingStatus={loadingStatus}
        striped={true}
        onRowClick={handleOnRowClick}
        additionalActions={
          <Box flexGrow={1}>
            <Button
              variant={"link"}
              colorScheme={"blue"}
              onClick={handleEditOrCreateOfficeGroupFormPrompt()}
            >
              + Create office group
            </Button>
          </Box>
        }
      />
    </Box>
  );
});
