import React, { useCallback, useMemo } from "react";
import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import * as Yup from "yup";
import { REQUIRED_FIELD } from "constants/validator-messages";
import { AccountIntegrationsStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrations.store";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import { observer } from "mobx-react";
import {
  TAccountIntegrationData,
  TBymDatawarehouseConfig,
} from "types/account-integration.type";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { ApiMessageStack, FormControlV2 } from "components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { ToggleIntegrationActiveSwitchInput } from "./ToggleIntegrationActiveSwitchInput";

const validationSchema = Yup.object().shape({
  instanceId: Yup.string().required(REQUIRED_FIELD),
});
type TFieldValues = {
  active: boolean;
  instanceId: string;
};

interface IProps {
  accountIntegrationsStore: AccountIntegrationsStore;
}
export const BymDatawarehouseIntegrationForm: React.FC<IProps> = observer(
  ({ accountIntegrationsStore }) => {
    const toast = useToast();
    const accountIntegration = accountIntegrationsStore
      .accountIntegrationsMapByType[
      AccountIntegrationTypeEnum.BYM_DATAWAREHOUSE
    ] as TAccountIntegrationData<TBymDatawarehouseConfig>;

    const initialValues: TFieldValues = useMemo(
      () => ({
        active: accountIntegration.active,
        instanceId: accountIntegration.integrationConfig.instanceId,
      }),
      [accountIntegration]
    );

    const {
      handleSubmit,
      control,
      watch,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    const { active } = watch();

    const onSubmit = useCallback(
      async ({ instanceId, active }: TFieldValues) => {
        try {
          await accountIntegrationsStore.updateAccountIntegration<
            TBymDatawarehouseConfig
          >(accountIntegration.id, {
            type: accountIntegration.type,
            active: active,
            integrationConfig: {
              instanceId,
            },
          });
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description:
              "Brief Your Market Datawarehouse integration configuration updated.",
          });
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        }
      },
      [
        accountIntegration.id,
        accountIntegration.type,
        accountIntegrationsStore,
        toast,
      ]
    );

    return (
      <Box w={"100%"}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ToggleIntegrationActiveSwitchInput<TFieldValues>
            label={"Connect with Brief Your Market data warehouse"}
            name={"active"}
            value={active}
            control={control}
          />
          <Box mb={5}>
            <FormControlV2<TFieldValues>
              name={"instanceId"}
              label={"Brief Your Market Instance ID"}
              control={control}
              type={FormControlsTypeEnum.TEXT}
              additionalProps={{
                placeholder: "Brief Your Market Instance ID",
              }}
            />
          </Box>
          <Box
            mb={5}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          ></Box>
          <Divider mb={4} />
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-end"}
          >
            <Button
              type={"submit"}
              colorScheme={"blue"}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Update
            </Button>
          </Box>
        </form>
      </Box>
    );
  }
);
