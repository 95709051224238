import {
  Box,
  Button,
  ChakraStyledOptions,
  Image,
  useToast,
} from "@chakra-ui/react";
import { faCircleCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApiMessageStack, Tooltip } from "components";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";
import {
  IntegrationLogoIcons,
  IntegrationLogoIconURLs,
} from "constants/integrationLogoIcons";
import { observer } from "mobx-react";
import { FC, useCallback, useMemo, useState } from "react";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { TLeadAltoAddress } from "types/alto.type";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { LeadAltoSyncFormPrompt } from "./LeadAltoSyncFormPrompt";

interface IProps {
  leadStore: AccountLeadStore;
  hasActiveAltoIntegration: boolean;
  styles?: ChakraStyledOptions;
}

export const LeadSyncAltoButton: FC<IProps> = observer(
  ({ leadStore, hasActiveAltoIntegration, styles }) => {
    const toast = useToast();
    const [isSyncing, setIsSyncing] = useState(false);
    const { setModal, unSetModal } = useActionPrompt();
    const isLeadOfficeSyncedWithAlto = leadStore.isLeadOfficeSyncedWithAlto;
    const isLeadSynced = leadStore.isLeadSyncedToAlto;
    const isAltoSyncAllowedForLeadType = leadStore.isAltoSyncAllowedForLeadType;
    const isLeadAssignedToOffice = leadStore.isLeadAssignedToOffice;
    const isLeadAssignedToUser = leadStore.isLeadAssignedToUser;
    const validationErrorMessages = useMemo(() => {
      const errorMessages: string[] = [];

      if (isSyncing) errorMessages.push("Lead is being synced...");
      if (isLeadSynced) errorMessages.push("Lead already synced.");
      if (!isAltoSyncAllowedForLeadType)
        errorMessages.push("Lead type not supported.");
      if (!isLeadAssignedToOffice)
        errorMessages.push("Please assign an office.");
      if (!isLeadOfficeSyncedWithAlto)
        errorMessages.push("Assigned office is not synced with Alto");
      if (!isLeadAssignedToUser) errorMessages.push("Please assign a user.");

      return errorMessages;
    }, [
      isSyncing,
      isLeadSynced,
      isAltoSyncAllowedForLeadType,
      isLeadAssignedToOffice,
      isLeadOfficeSyncedWithAlto,
      isLeadAssignedToUser,
    ]);

    const buttonData = useMemo(() => {
      return {
        buttonProps: {
          isDisabled: !!validationErrorMessages.length,
          isLoading: isSyncing,
          children: isLeadSynced ? "Synced to Alto" : "Sync to Alto",
          leftIcon: isLeadSynced ? (
            <FontAwesomeIcon icon={faCircleCheck} fontSize={24} />
          ) : (
            <Image
              alt={"Alto Logo"}
              width={"24px"}
              src={IntegrationLogoIconURLs[IntegrationLogoIcons.ALTO]}
            />
          ),
        },
      };
    }, [isLeadSynced, isSyncing, validationErrorMessages.length]);

    const handleSyncLead = useCallback(
      async (address: TLeadAltoAddress) => {
        if (hasActiveAltoIntegration && !validationErrorMessages.length) {
          try {
            setIsSyncing(true);
            await leadStore.syncLeadWithAlto(address);
          } catch (e) {
            toast({
              ...DEFAULT_ERROR_TOAST_OPTIONS,
              description: <ApiMessageStack messageStack={e.message} />,
            });
          } finally {
            setIsSyncing(false);
          }
        }
      },
      [leadStore, hasActiveAltoIntegration, validationErrorMessages, toast]
    );

    const handleOpenSyncModal = useCallback(() => {
      setModal(
        <LeadAltoSyncFormPrompt
          leadStore={leadStore}
          handleSyncLead={handleSyncLead}
          closePrompt={unSetModal}
        />
      );
    }, [leadStore, handleSyncLead, setModal, unSetModal]);

    return (
      <Tooltip
        isDisabled={!validationErrorMessages.length}
        aria-label="lead-alto-sync-tooltip"
        label={
          <Box>
            {validationErrorMessages.map(message => (
              <Box key={message}>{message}</Box>
            ))}
          </Box>
        }
      >
        <Box>
          <Button
            onClick={handleOpenSyncModal}
            variant="ghost"
            {...buttonData.buttonProps}
            {...styles}
          />
        </Box>
      </Tooltip>
    );
  }
);
