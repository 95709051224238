import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import React, { useCallback } from "react";
import * as Yup from "yup";
import { REQUIRED_FIELD } from "constants/validator-messages";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import { observer } from "mobx-react";
import { AccountIntegrationsStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrations.store";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { ApiMessageStack, FormControlV2 } from "components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TBymDatawarehouseConfig } from "types/account-integration.type";

type TFieldValues = {
  instanceId: string;
};

const initialValues: TFieldValues = {
  instanceId: "",
};

const validationSchema = Yup.object().shape({
  instanceId: Yup.string().required(REQUIRED_FIELD),
});

interface IProps {
  closePrompt: () => void;
  accountIntegrationsStore: AccountIntegrationsStore;
}
export const ConnectBymDatawarehouseForm: React.FC<IProps> = observer(
  ({ closePrompt, accountIntegrationsStore }) => {
    const toast = useToast();

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    const onSubmit = useCallback(
      async ({ instanceId }: TFieldValues) => {
        try {
          await accountIntegrationsStore.createAccountIntegration<
            TBymDatawarehouseConfig
          >({
            type: AccountIntegrationTypeEnum.BYM_DATAWAREHOUSE,
            active: true,
            integrationConfig: {
              instanceId,
            },
          });

          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack
                messageStack={"BYM Datawarehouse successfully connected"}
              />
            ),
          });
          closePrompt();
        } catch (e) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={e.message} />,
          });
        }
      },
      [accountIntegrationsStore, closePrompt, toast]
    );

    return (
      <Box pt={5}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={5}>
            <FormControlV2<TFieldValues>
              name={"instanceId"}
              control={control}
              label={"BYM datawarehouse instance id"}
              type={FormControlsTypeEnum.TEXT}
              additionalProps={{
                placeholder: "Enter BYM instance id",
              }}
            />
          </Box>
          <Divider mb={4} />
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-end"}
          >
            <Button
              type={"submit"}
              colorScheme={"blue"}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Connect
            </Button>
          </Box>
        </form>
      </Box>
    );
  }
);
