import { BasicUserInfo, IntegrationEntitySyncTag } from "components";
import { DefaultHeaderCell } from "components/table-v2/header-cells";
import { DefaultRowCell } from "components/table-v2/row-cells";
import { ITableV2Column } from "components/table-v2/table-v2";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { TOpenViewSyncNegotiatorExtendedData } from "types/openview.type";
import { OpenViewNegotiatorSyncSelect } from "./OpenViewNegotiatorSyncSelect";

export const syncOpenViewNegotiatorTableColumnDef = (
  accountStore: UserAccountStore,
  updateSyncPair: (syncPair: TOpenViewSyncNegotiatorExtendedData) => void
): ITableV2Column<TOpenViewSyncNegotiatorExtendedData>[] => {
  return [
    {
      accessor: "userFullName",
      Header: <DefaultHeaderCell>LeadPro user</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          <BasicUserInfo user={row.user} />
        </DefaultRowCell>
      ),
    },
    {
      accessor: "openViewNegotiatorId",
      excludeFromGlobalFilter: true,
      disableSortBy: true,
      Header: <DefaultHeaderCell>OpenView negotiator</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          <OpenViewNegotiatorSyncSelect
            accountStore={accountStore}
            record={row}
            onChange={updateSyncPair}
          />
        </DefaultRowCell>
      ),
    },
    {
      accessor: "syncStatus",
      excludeFromGlobalFilter: true,
      Header: <DefaultHeaderCell>Status</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          <IntegrationEntitySyncTag syncStatus={row.syncStatus} />
        </DefaultRowCell>
      ),
    },
  ];
};
