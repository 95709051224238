import React, { useEffect, useMemo, useState } from "react";
import { SETTINGS_DEVELOPERS_WEBHOOKS_ROUTE } from "constants/routes";
import { observer } from "mobx-react";
import { AccountDevelopersWebhooksStore } from "store/UserAccounts/UserAccount/AccountDevelopers/AccountDevelopersWebhooks/AccountDevelopersWebhooks.store";
import { Redirect, useParams } from "react-router-dom";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { TabsGroupLayout } from "routes/dashboard/components/Layouts/TabsGroupLayout";
import { DevelopersConfigWebhookDetails } from "./DevelopersConfigWebhookDetails";
import { DevelopersConfigWebhookRequests } from "./DevelopersConfigWebhookRequests/DevelopersConfigWebhookRequests";
import { Box } from "@chakra-ui/react";

interface IProps {
  accountDevelopersWebhooksStore: AccountDevelopersWebhooksStore;
}

export const DevelopersConfigWebhook: React.FC<IProps> = observer(
  ({ accountDevelopersWebhooksStore }) => {
    let { id } = useParams<{ id: string }>();
    const webhookId = useMemo(() => parseInt(id!), [id]);
    const ref = React.useRef<HTMLDivElement | null>(null);
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );

    const webhookStore =
      accountDevelopersWebhooksStore.webhookStoresMap[webhookId];

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await accountDevelopersWebhooksStore.fetchWebhook(webhookId);
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [accountDevelopersWebhooksStore, webhookId]);

    const content = useMemo(() => {
      const title = webhookStore?.webhookConfig.name || "Loading webhook...";
      return (
        <Box
          position={"relative"}
          height={"100%"}
          width={"100%"}
          display={"flex"}
          flexDirection={"column"}
          flex={1}
          overflowY={"hidden"}
          ref={ref}
        >
          <TabsGroupLayout
            title={title}
            loadingStatus={loadingStatus}
            config={[
              {
                tabName: "Details",
                tabHash: "#details",
                tabComponent: !!webhookStore && (
                  <DevelopersConfigWebhookDetails
                    accountDevelopersWebhookStore={webhookStore}
                    containerRef={ref}
                  />
                ),
              },
              {
                tabName: "Logs",
                tabHash: "#logs",
                tabComponent: !!webhookStore && (
                  <DevelopersConfigWebhookRequests
                    accountDevelopersWebhookStore={webhookStore}
                  />
                ),
              },
            ]}
          />
        </Box>
      );
    }, [webhookStore, loadingStatus]);

    if (loadingStatus === ApiRequestStatusEnum.ERROR) {
      return (
        <Redirect
          to={{
            pathname: SETTINGS_DEVELOPERS_WEBHOOKS_ROUTE,
          }}
        />
      );
    }

    return content;
  }
);
