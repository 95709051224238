import { Box, Portal, SimpleGrid, useToast } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import React, {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AccountEmailNotificationsItem } from "./AccountEmailNotificationsItem";
import { AccountEmailNotificationPreferenceEnum } from "enums/account-email-notification-preference.enum";
import { FormFooter } from "components/form";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { ApiMessageStack, TagInput } from "components";
import { accountEmailNotificationTilesConfig } from "./accountEmailNotificationTilesConfig";

interface IProps {
  accountStore: UserAccountStore;
  containerRef: MutableRefObject<HTMLDivElement | null>;
}

export const AccountEmailNotifications: FC<IProps> = observer(
  ({ accountStore, containerRef }) => {
    const toast = useToast();
    const accountData = accountStore.account;
    const [currentlySelectedOption, setCurrentlySelectedOption] = useState<
      AccountEmailNotificationPreferenceEnum
    >(accountData.emailNotificationPreferences);
    const [currentEmailsList, setCurrentEmailsList] = useState<string[]>(
      accountStore.accountContactEmails
    );
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    useEffect(() => {
      setCurrentlySelectedOption(accountData.emailNotificationPreferences);
      setCurrentEmailsList(accountStore.accountContactEmails);
    }, [
      setCurrentlySelectedOption,
      setCurrentEmailsList,
      accountData.emailNotificationPreferences,
      accountStore.accountContactEmails,
    ]);

    const dirty = useMemo(
      () =>
        currentlySelectedOption !== accountData.emailNotificationPreferences ||
        currentEmailsList !== accountStore.accountContactEmails,
      [
        currentlySelectedOption,
        currentEmailsList,
        accountData.emailNotificationPreferences,
        accountStore.accountContactEmails,
      ]
    );

    const onItemClick = useCallback(
      (value: AccountEmailNotificationPreferenceEnum) => {
        setCurrentlySelectedOption(value);
      },
      [setCurrentlySelectedOption]
    );

    const handleSubmit = useCallback(async () => {
      try {
        setIsSubmitting(true);
        await accountStore.update({
          emailNotificationPreferences: currentlySelectedOption,
          contactEmails: currentEmailsList.length
            ? currentEmailsList.join(",")
            : null,
        });
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack
              messageStack={"Account email notifications preferences updated"}
            />
          ),
        });
      } catch (e) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      } finally {
        setIsSubmitting(false);
      }
    }, [
      setIsSubmitting,
      currentlySelectedOption,
      currentEmailsList,
      accountStore,
      toast,
    ]);

    const resetForm = useCallback(() => {
      setCurrentlySelectedOption(accountData.emailNotificationPreferences);
      setCurrentEmailsList(accountStore.accountContactEmails);
    }, [
      setCurrentlySelectedOption,
      setCurrentEmailsList,
      accountStore.accountContactEmails,
      accountData.emailNotificationPreferences,
    ]);

    return (
      <>
        <SimpleGrid columns={2} spacing={8} p={1}>
          {accountEmailNotificationTilesConfig.map(config => (
            <AccountEmailNotificationsItem
              key={config.value}
              selectedValue={currentlySelectedOption}
              onClick={onItemClick}
              {...config}
            />
          ))}
        </SimpleGrid>
        <Box px={1} mt={12}>
          <Box
            fontSize={"2xl"}
            fontWeight={"bold"}
            color={"leadpro.700"}
            mb={4}
          >
            Technical notifications
          </Box>

          <Box color={"leadpro.500"} mb={4}>
            If you like to receive email notifications about technical aspects
            of your Leadpro system, such as webhook expiry warnings, provide one
            or more email addresses below.
          </Box>

          <TagInput
            value={currentEmailsList}
            onChange={value => setCurrentEmailsList(value)}
            placeholder={
              "Provide one or more email addresses here to enable technical notifications"
            }
          />
        </Box>
        {dirty && (
          <Portal containerRef={containerRef}>
            <FormFooter
              isSubmitting={isSubmitting}
              submitForm={handleSubmit}
              resetForm={resetForm}
            />
          </Portal>
        )}
      </>
    );
  }
);
