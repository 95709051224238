import {
  TAltoOffice,
  TAltoOfficeRelation,
  TLeadAltoAddress,
} from "types/alto.type";
import { TIntegrationOfficeRelationData } from "../types/integration-sync-office.type";
import { TIntegrationOffice } from "../store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrationOfficesStore.store";

export const altoOfficeDataToIntegrationOfficeDataMapper = (
  altoOffices: TAltoOffice[]
): TIntegrationOffice[] => {
  return altoOffices.map(altoOffice => {
    return {
      id: altoOffice.altoId,
      name: altoOffice.altoName,
    };
  });
};

export const mapIntegrationOfficeRelationsToAltoOfficeRelations = (
  relations: TIntegrationOfficeRelationData[]
) => {
  return relations.map(relation => {
    return {
      altoId: relation.branchId,
      officeId: relation.officeId,
    };
  });
};
export const mapAltoOfficeRelationsToIntegrationOfficeRelations = (
  altoOfficeRelations: TAltoOfficeRelation[]
) => {
  return altoOfficeRelations.map(altoOfficeRelation => {
    return {
      branchId: altoOfficeRelation.altoId,
      officeId: altoOfficeRelation.officeId,
    };
  });
};

export const getAltoAddressFields = (
  address: string,
  postcode: string
): TLeadAltoAddress => {
  const altoAddress = {
    subDwelling: "",
    nameNumber: "",
    street: "",
    locality: "",
    town: "",
    county: "",
    postcode: postcode || "",
  };

  if (!address) {
    return altoAddress;
  }

  const parts = address.split(",").map(part => part.trim());

  const postcodeRegex = /[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9][A-Z]{2}/i;
  const postcodeIndex = parts.findIndex(part => postcodeRegex.test(part));

  if (postcodeIndex >= 0) {
    parts.splice(postcodeIndex, 1);
  }

  if (parts.length > 0) altoAddress.nameNumber = parts[0];
  if (parts.length > 1) altoAddress.street = parts[1];
  if (parts.length > 2) altoAddress.locality = parts[2];
  if (parts.length > 3) altoAddress.town = parts[3];
  if (parts.length > 4) altoAddress.county = parts[4];

  return altoAddress;
};
