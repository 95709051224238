import { LeadSourceEnum } from "enums/lead-source.enum";
import { LeadTypeEnum } from "enums/lead-type.enum";
import { TGetLeadsResponse, TLeadWithDetails } from "types/lead.type";

export const dummyLeadsList: TGetLeadsResponse = {
  data: [
    {
      id: 11459,
      createdAt: "2021-05-31T15:12:18.528Z",
      updatedAt: "2021-06-01T10:07:29.000Z",
      source: LeadSourceEnum.ivt,
      type: LeadTypeEnum.Landlord,
      postcode: "B219EP",
      address: "30 Grove Lane, Handsworth, B219EP",
      advertPostcode: null,
      advertAddress: null,
      nextActionDate: null,
      outOfAreaDistance: null,
      assignedToId: null,
      statusId: 1,
      questionnaireAnswered: false,
      journeys: [],
      person: {
        id: 8872,
        fullName: "Sam Wiseman",
        firstName: "Sam",
        lastName: "Wiseman",
        title: null,
        phone: "07123456789",
        email: "samwiseman@demo.com",
        address: "30 Grove Lane, Handsworth, B219EP",
        createdAt: "2021-05-31T15:12:18.431Z",
        updatedAt: "2021-05-31T15:12:18.431Z",
        postcode: "B219EP",
        leads: [],
        notes: [],
      },
      utm: {
        source: null,
        medium: null,
        campaign: null,
      },
    },
  ],
  meta: {
    offset: 0,
    limit: 100,
    unreadTotalLeads: 4,
    inProgressTotalLeads: 78,
    businessTotalLeads: 76,
    noBusinessTotalLeads: 13,
    overallTotalLeads: 188,
    count: 1,
  },
};
export const dummyLeadDetailsData: TLeadWithDetails = {
  id: 11459,
  createdAt: "2021-05-31T15:12:18.528Z",
  updatedAt: "2021-06-01T10:07:29.000Z",
  source: LeadSourceEnum.ivt,
  type: LeadTypeEnum.Landlord,
  isParent: true,
  statusId: 1,
  journeys: [],
  additionalData: {
    enquiry: {
      email: "samwiseman@demo.com",
      fromPortal: "ivt",
      firstName: "Sam",
      lastName: "Wiseman",
      name: "Sam Wiseman",
      phone: "07123456789",
      propertyBedrooms: 2,
      address: "30 Grove Lane, Handsworth, B219EP",
      currentPostcode: "B219EP",
      // gdpr_checkbox: null,
      host: "demobrand.stg.lead.pro",
      eta: "ASAP",
      kind: LeadTypeEnum.Landlord,
      propertyAddress: "",
      propertyImageUrl: "",
      propertyPostcode: "",
      propertyPrice: 0,
      rawPropertyReference: "",
      portalEnquiryId: 0,
      additionalInfo: "",
      listingUrl: "",
      message: "",
    },
    saleEstimation: {
      est: 150000,
      min: 130000,
      max: 170000,
    },
    rentEstimation: {
      est: 789,
      min: 684,
      max: 894,
    },
  },
  postcode: "B219EP",
  address: "30 Grove Lane, Handsworth, B219EP",
  advertPostcode: null,
  advertAddress: null,
  nextActionDate: null,
  outOfAreaDistance: null,
  assignedToId: 36,
  questionnaireAnswered: false,
  person: {
    id: 8872,
    fullName: "Sam Wiseman",
    firstName: "Sam",
    lastName: "Wiseman",
    title: null,
    phone: "07123456789",
    email: "samwiseman@demo.com",
    address: "30 Grove Lane, Handsworth, B219EP",
    createdAt: "2021-05-31T15:12:18.431Z",
    updatedAt: "2021-05-31T15:12:18.431Z",
    postcode: "B219EP",
    leads: [
      {
        id: 11459,
        createdAt: "2021-05-31T15:12:18.528Z",
        updatedAt: "2021-06-01T10:07:29.000Z",
        source: LeadSourceEnum.ivt,
        type: LeadTypeEnum.Landlord,
        postcode: "B219EP",
        address: "30 Grove Lane, Handsworth, B219EP",
        advertPostcode: null,
        advertAddress: null,
        nextActionDate: null,
        outOfAreaDistance: null,
        assignedToId: null,
        statusId: 1,
        questionnaireAnswered: false,
        journeys: [],
        person: {
          id: 8872,
          fullName: "Sam Wiseman",
          firstName: "Sam",
          lastName: "Wiseman",
          title: null,
          phone: "07123456789",
          email: "samwiseman@demo.com",
          address: "30 Grove Lane, Handsworth, B219EP",
          createdAt: "2021-05-31T15:12:18.431Z",
          updatedAt: "2021-05-31T15:12:18.431Z",
          postcode: "B219EP",
          leads: [],
          notes: [],
        },
        utm: {
          source: null,
          medium: null,
          campaign: null,
        },
      },
    ],
    notes: [],
  },
  office: null,
  officeId: null,
  assignedTo: {
    id: 36,
    firstName: "Adam",
    lastName: "Price",
    email: "adsp16@gmail.com",
    createdAt: "2021-05-10T21:27:54.200Z",
    updatedAt: "2021-06-07T13:50:37.000Z",
    phoneNumber: "07591206911",
    completedDashboardTours: [],
    isEcosystemUser: false,
    webPushSettings: {
      [LeadTypeEnum.Sale]: false,
      [LeadTypeEnum.Vendor]: false,
      [LeadTypeEnum.Landlord]: false,
      [LeadTypeEnum.Let]: false,
      [LeadTypeEnum.MortgageRequest]: false,
      [LeadTypeEnum.MarketAppraisal]: false,
    },
    sms2FAEnabled: false,
    availableForLeadAssignment: true,
  },
  events: [],
  utm: {
    source: null,
    medium: null,
    campaign: null,
  },
};
