import { TLeadOpenViewAddress } from "types/openview.type";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { FC, useCallback, useMemo } from "react";
import { observer } from "mobx-react";
import { getOpenViewSegmentedAddress } from "utils/openview.utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ActionPromptContainer, FormControlV2, Message } from "components";
import { Box, Button, Divider } from "@chakra-ui/react";
import { AlertStatusEnum } from "enums/alert-status.enum";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { LeadTypeEnum } from "enums/lead-type.enum";
import { OpenviewValidationSchema } from "utils/validation-schemas/openview-sync.validation";

type TFieldValues = TLeadOpenViewAddress;

interface IProps {
  leadStore: AccountLeadStore;
  handleSyncLead: (address: TLeadOpenViewAddress) => Promise<void>;
  closePrompt: () => void;
}

export const LeadOpenViewSyncFormPrompt: FC<IProps> = observer(
  ({ leadStore, handleSyncLead, closePrompt }) => {
    const { address, postcode, type } = leadStore.lead;

    const initialValues: TFieldValues = useMemo(() => {
      const brokenDownAddress = getOpenViewSegmentedAddress(address, postcode);
      return {
        houseNo: brokenDownAddress.houseNo || "",
        address1: brokenDownAddress.address1 || "",
        address2: brokenDownAddress.address2 || "",
        town: brokenDownAddress.town || "",
        county: brokenDownAddress.county || "",
        postcode: brokenDownAddress.postcode || "",
        countryName: brokenDownAddress.countryName || "",
        fullAddress: brokenDownAddress.fullAddress || "",
        shortAddress: brokenDownAddress.shortAddress || "",
      };
    }, [address, postcode]);

    const isApplicant = [LeadTypeEnum.Sale, LeadTypeEnum.Let].includes(type);

    const validationSchema = useMemo(() => {
      return OpenviewValidationSchema(isApplicant);
    }, [isApplicant]);

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    const onSubmit = useCallback(
      async (values: TLeadOpenViewAddress) => {
        await handleSyncLead(values);
        closePrompt();
      },
      [handleSyncLead, closePrompt]
    );

    return (
      <ActionPromptContainer
        header={"Sync lead to OpenView"}
        body={
          <Box pt={5}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Message status={AlertStatusEnum.WARNING} mb={5}>
                <Box>
                  {`Please confirm the address below is correct before syncing to OpenView.`}
                </Box>
              </Message>
              <Box mb={5}>
                <FormControlV2<TFieldValues>
                  name={"houseNo"}
                  control={control}
                  label={"House number/name"}
                  isOptional={isApplicant}
                  type={FormControlsTypeEnum.TEXT}
                />
              </Box>
              <Box mb={5}>
                <FormControlV2<TFieldValues>
                  name={"address1"}
                  control={control}
                  label={"Address line 1"}
                  isOptional={isApplicant}
                  type={FormControlsTypeEnum.TEXT}
                />
              </Box>
              <Box mb={5}>
                <FormControlV2<TFieldValues>
                  name={"address2"}
                  control={control}
                  label={"Address line 2"}
                  isOptional
                  type={FormControlsTypeEnum.TEXT}
                />
              </Box>
              <Box mb={5}>
                <FormControlV2<TFieldValues>
                  name={"town"}
                  control={control}
                  label={"Town"}
                  isOptional
                  type={FormControlsTypeEnum.TEXT}
                />
              </Box>
              <Box mb={5}>
                <FormControlV2<TFieldValues>
                  name={"county"}
                  control={control}
                  label={"County"}
                  isOptional
                  type={FormControlsTypeEnum.TEXT}
                />
              </Box>
              <Box mb={5}>
                <FormControlV2<TFieldValues>
                  name={"postcode"}
                  control={control}
                  label={"Postcode"}
                  isOptional={isApplicant}
                  type={FormControlsTypeEnum.TEXT}
                />
              </Box>
              <Box mb={5}>
                <FormControlV2<TFieldValues>
                  name={"fullAddress"}
                  control={control}
                  label={"Full Address"}
                  isOptional
                  type={FormControlsTypeEnum.TEXT}
                />
              </Box>
              <Divider mb={4} />
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
              >
                <Button
                  type={"submit"}
                  colorScheme={"blue"}
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Sync to OpenView
                </Button>
              </Box>
            </form>
          </Box>
        }
      />
    );
  }
);
