import { Box, HStack } from "@chakra-ui/react";
import { DateRangePickerInput } from "components";
import React, { FC, useCallback } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { AnalyticsOfficesFilter } from "./AnalyticsOfficesFilter/AnalyticsOfficesFilter";
import { observer } from "mobx-react";
import { END_OF_TODAY } from "constants/date";
import { AnalyticsDateRangeFilterOptions } from "constants/date-range-filter-options";
import { TSerializedDateRange } from "types/date.type";
import { AnalyticsDateRangeFilterEnum } from "enums/analytics-date-range-filter.enum";
import { getDatesForPresetOption } from "utils/analytics-date-range-filters.utils";

interface IProps {
  accountStore: UserAccountStore;
}

export const AnalyticsFiltersRow: FC<IProps> = observer(({ accountStore }) => {
  const { accountAnalyticsStore } = accountStore;
  const { accountAnalyticsFiltersStore } = accountAnalyticsStore;
  const { dateRange, changeDateRange } = accountAnalyticsFiltersStore;

  const handleSelectOption = useCallback(
    (option: string) => {
      const type = option as AnalyticsDateRangeFilterEnum;
      if (type === dateRange.type) return;
      changeDateRange({
        ...getDatesForPresetOption(type),
        type: type,
      });
    },
    [changeDateRange, dateRange]
  );

  const handleDateRangeChange = useCallback(
    (serializedRange: TSerializedDateRange) => {
      changeDateRange({
        ...serializedRange,
        type: AnalyticsDateRangeFilterEnum.CUSTOM,
      });
    },
    [changeDateRange]
  );

  return (
    <HStack justifyContent={"flex-end"} width={"100%"} spacing={4}>
      <AnalyticsOfficesFilter accountStore={accountStore} />
      <Box shadow={"sm"} rounded={"md"}>
        <DateRangePickerInput
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          onDatesChange={handleDateRangeChange}
          maxDate={END_OF_TODAY}
          presetOptions={AnalyticsDateRangeFilterOptions}
          onSelectPreset={handleSelectOption}
          selectedPresetOption={dateRange.type}
        />
      </Box>
    </HStack>
  );
});
