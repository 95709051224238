import React, { useCallback, useState } from "react";
import {
  ILeadBlockDefinition,
  LeadBlockTypes,
  leadBlockHoc,
} from "utils/hoc/leadBlock.hoc";
import { LeadStatusSelect } from "./LeadStatusSelect";
import { LeadAssigneeSelect } from "./LeadAssigneeSelect";
import { LeadNextActionDatePicker } from "./LeadNextActionDatePicker";
import { LeadOfficeSelect } from "./LeadOfficeSelect";
import { LeadBlockHeader } from "../../LeadBlockHeader";
import { LeadBlockBody } from "../../LeadBlockBody";
import { Center, Grid, GridItem } from "@chakra-ui/react";
import { LeadCallButton } from "./LeadCallButton/LeadCallButton";
import { useToast } from "@chakra-ui/react";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { ApiMessageStack } from "components";
import { TLead } from "types/lead.type";

export interface ILeadSettingsBlockDefinition extends ILeadBlockDefinition {
  blockType: LeadBlockTypes.SettingsBlock;
}

export const LeadSettingsBlock = leadBlockHoc<ILeadSettingsBlockDefinition>(
  ({ leadBlockDefinition }) => {
    const toast = useToast();
    const { leadStore, accountStore } = leadBlockDefinition;
    const { accountLeadStatusesStore } = accountStore;
    const accountId = accountStore.account.id;

    const isStrictModeEnabled =
      accountStore.accountLeadProgressionStore
        .accountLeadProgressionConfiguration?.isStrictModeEnabled;

    const isAutocallerEnabled =
      accountStore.accountAutocallerStore.isAutocallerEnabled;

    const newStatusId = accountLeadStatusesStore.newStatus?.id;

    const leadStatus = leadStore.lead.statusId;
    const [hasBeenContacted, setHasBeenContacted] = useState(
      leadStatus !== newStatusId
    );

    const onCallSuccessCallback = useCallback(
      async (lead: TLead) => {
        try {
          leadStore.upsertLeadData(lead);
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: "Lead status has been automatically updated.",
          });
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        } finally {
          setHasBeenContacted(true);
        }
      },
      [leadStore, toast]
    );

    return (
      <>
        <LeadBlockHeader>Lead position</LeadBlockHeader>
        <LeadBlockBody
          styles={{
            px: 2,
            py: 5,
          }}
        >
          <Grid templateColumns={"repeat(2, 1fr)"} gap={"9px"}>
            <GridItem>
              <LeadStatusSelect
                leadStore={leadStore}
                leadStatusesStore={accountLeadStatusesStore}
                disabled={isStrictModeEnabled && !hasBeenContacted}
                isStrictModeEnabled={!!isStrictModeEnabled}
              />
            </GridItem>
            <GridItem>
              <LeadAssigneeSelect leadStore={leadStore} />
            </GridItem>
            <GridItem>
              <LeadNextActionDatePicker leadStore={leadStore} />
            </GridItem>
            <GridItem>
              <LeadOfficeSelect
                leadStore={leadStore}
                accountStore={accountStore}
              />
            </GridItem>
            {isAutocallerEnabled && (
              <GridItem colSpan={2}>
                <Center>
                  <LeadCallButton
                    accountId={accountId}
                    leadStore={leadStore}
                    onCallSuccessCallback={onCallSuccessCallback}
                    disabled={hasBeenContacted}
                    isStrictModeEnabled={isStrictModeEnabled}
                  />
                </Center>
              </GridItem>
            )}
          </Grid>
        </LeadBlockBody>
      </>
    );
  }
);
