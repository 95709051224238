import { Box, Center, Divider, HStack, Image, VStack } from "@chakra-ui/react";
import React, { FC, useMemo } from "react";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import { ReapitIntegrationConfigurator } from "./ReapitIntegrationConfigurator/ReapitIntegrationConfigurator";
import { TAccountIntegrationsStaticData } from "types/account-integration.type";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { ApplicationProductEnum } from "enums/application-product.enum";
import { PremiumFeatureBlock } from "routes/dashboard/components/PremiumFeatureBlock/PremiumFeatureBlock";
import { PaidFeatureBanner } from "components";
import { ZooplaIntegrationConfigurator } from "./ZooplaIntegrationConfigurator/ZooplaIntegrationConfigurator";
import { ReapitIRIntegrationConfigurator } from "./ReapitIRIntegrationConfigurator/ReapitIRIntegrationConfigurator";
import { RightmoveIntegrationConfigurator } from "./RightmoveIntegrationConfigurator/RightmoveIntegrationConfigurator";
import { GraingerSalesforceIntegrationForm } from "./GraingerSalesforceIntegrationForm";
import { DripIntegrationConfigurator } from "./DripIntegrationConfigurator/DripIntegrationConfigurator";
import { MRIRadarIntegrationConfigurator } from "./MRIRadarIntegrationConfigurator/MRIRadarIntegrationConfigurator";
import { StreetIntegrationConfigurator } from "./StreetIntegrationConfigurator/StreetIntegrationConfigurator";
import { OpenViewIntegrationConfigurator } from "./OpenViewIntegrationConfigurator/OpenViewIntegrationConfigurator";
import { RexIntegrationConfigurator } from "./RexIntegrationConfigurator/RexIntegrationConfigurator";
import { AltoIntegrationConfigurator } from "./AltoAccountIntegrationConfigurator/AltoIntegrationConfigurator";
import { UrlSyncIntegrationConfigurator } from "./UrlSyncIntegrationConfigurator/UrlSyncIntegrationConfigurator";
import { BymDatawarehouseIntegrationForm } from "./BymDatawarehouseIntegrationForm";

interface IProps {
  staticData: TAccountIntegrationsStaticData;
  accountStore: UserAccountStore;
}

export const AccountIntegrationSettingsPageContent: FC<IProps> = observer(
  ({ staticData, accountStore }) => {
    const accountIntegrationsStore = accountStore.accountIntegrationsStore;

    const configuratorComponent = useMemo(() => {
      switch (staticData.type) {
        case AccountIntegrationTypeEnum.ZOOPLA:
          return <ZooplaIntegrationConfigurator accountStore={accountStore} />;
        case AccountIntegrationTypeEnum.DRIP:
          return <DripIntegrationConfigurator accountStore={accountStore} />;
        case AccountIntegrationTypeEnum.RIGHTMOVE:
          return (
            <RightmoveIntegrationConfigurator accountStore={accountStore} />
          );
        case AccountIntegrationTypeEnum.REAPIT:
          return (
            <PremiumFeatureBlock
              productId={ApplicationProductEnum.REAPIT_INTEGRATION}
              render={() => (
                <ReapitIntegrationConfigurator accountStore={accountStore} />
              )}
              alternativeRender={() => <PaidFeatureBanner />}
            />
          );
        case AccountIntegrationTypeEnum.REAPIT_INTERNET_REGISTRATION:
          return (
            <ReapitIRIntegrationConfigurator accountStore={accountStore} />
          );
        case AccountIntegrationTypeEnum.MRI_RADAR:
          return (
            <MRIRadarIntegrationConfigurator accountStore={accountStore} />
          );
        case AccountIntegrationTypeEnum.GRAINGER_SALESFORCE:
          return (
            <GraingerSalesforceIntegrationForm
              accountIntegrationsStore={accountIntegrationsStore}
            />
          );
        case AccountIntegrationTypeEnum.STREET:
          return <StreetIntegrationConfigurator accountStore={accountStore} />;
        case AccountIntegrationTypeEnum.OPEN_VIEW:
          return (
            <OpenViewIntegrationConfigurator accountStore={accountStore} />
          );
        case AccountIntegrationTypeEnum.REX:
          return <RexIntegrationConfigurator accountStore={accountStore} />;
        case AccountIntegrationTypeEnum.URL_SYNC:
          return <UrlSyncIntegrationConfigurator accountStore={accountStore} />;
        case AccountIntegrationTypeEnum.ALTO:
          return <AltoIntegrationConfigurator accountStore={accountStore} />;
        case AccountIntegrationTypeEnum.BYM_DATAWAREHOUSE:
          return (
            <BymDatawarehouseIntegrationForm
              accountIntegrationsStore={accountIntegrationsStore}
            />
          );
        default:
          return null;
      }
    }, [staticData.type, accountStore, accountIntegrationsStore]);

    return (
      <VStack spacing={0} width={"100%"} height={"100%"}>
        <Box bg={"gray.50"} width={"100%"}>
          <HStack spacing={6} py={10} maxW={"4xl"} mx={"auto"}>
            <Center
              width={"100px"}
              height={"100px"}
              bg={"white"}
              rounded={"lg"}
              border={"1px solid"}
              borderColor={"gray.100"}
              flexShrink={0}
            >
              <Image src={staticData.logoUrl} height={"62px"} width={"62px"} />
            </Center>
            <Box ml={4}>
              <Box fontSize="2xl">{staticData.name}</Box>
              <Box fontSize="sm" color="gray.500" mb={3} fontWeight="normal">
                {staticData.description}
              </Box>
            </Box>
          </HStack>
        </Box>
        <Divider />
        <Box width={"100%"} overflowY={"auto"}>
          <Box maxW={"4xl"} mx={"auto"} my={10} flexGrow={1}>
            {configuratorComponent}
          </Box>
        </Box>
      </VStack>
    );
  }
);
